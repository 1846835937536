<template>
  <div class="edit-Password">
    <el-form :model="infoData" :rules="rules" ref="infoData" label-width="100px" class="demo-ruleForm">
      <el-form-item label="旧密码：" prop="oldPwd">
        <el-input type="password" v-model="infoData.oldPwd" placeholder="请输入旧密码" autocomplete="off" show-password></el-input>
      </el-form-item>
      <el-form-item label="新密码：" prop="newPwd">
        <el-input type="password" v-model="infoData.newPwd" placeholder="请输入新密码" autocomplete="off" show-password></el-input>
      </el-form-item>
      <el-form-item label="确认密码：" prop="newPwds">
        <el-input type="password" v-model="infoData.newPwds" placeholder="请再次输入密码" autocomplete="off" show-password></el-input>
      </el-form-item>
      <el-form-item style="margin-top: 40px;">
        <el-button type="primary" :loading="loading" @click="handleSave">确定</el-button>
        <el-button @click="resetForm">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import md5 from 'js-md5'
import { EditPwd, LoginAdminLogout } from '@/api/http'
export default {
  name: 'EditPassword',
  data () {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.infoData.newPwd) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      loading: false,
      infoData: {
        oldPwd: '',
        newPwd: '',
        newPwds: ''
      },
      rules: {
        oldPwd: [{ required: true, message: '请输入旧密码', trigger: 'blur' }, { pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/, message: '请输入8位以上字母和数字的组合', trigger: 'blur' }],
        newPwd: [{ required: true, message: '请输入新密码', trigger: 'blur' }, { pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/, message: '请输入8位以上字母和数字的组合', trigger: 'blur' }],
        newPwds: [
          { required: true, validator: validatePass, trigger: 'blur' }
        ]
      }
    }
  },
  watch: {},
  components: {},
  methods: {
    resetForm() {
      this.$refs.infoData.resetFields()
    },
    handleSave() {
      this.$refs.infoData.validate(valid => {
        if (!valid) return false
        this.loading = true
        EditPwd({ password: md5(this.infoData.oldPwd), newPassword: md5(this.infoData.newPwd) }).then(res => {
          this.loading = false
          if (res.code !== '000') return this.$message({ type: 'error', message: res.msg })
          this.$message({ type: 'success', message: '修改成功' })
          LoginAdminLogout().then(res => {
            if (res.code !== '000') return this.$message({ type: 'error', message: res.msg })
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('loginName', '')
            window.localStorage.setItem('userType', '')
            window.localStorage.setItem('authType', '')
            this.$router.push({ path: '/login' })
          })
        }).catch((err) => {
          this.loading = false
          console.log(err)
        })
      })
    }
  },
  created () {}
}
</script>

<style lang="less" scoped>
.edit-Password {
  width: 500px;
  margin: 100px auto;
}
</style>
